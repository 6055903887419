<template>
  <div>
    <div
      v-permission="'data_access.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="data_access"
      order_column="model_type">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="owner_id" sort-key="owner_id">{{ $t('data_access.owner_id') }}</vs-th>
        <vs-th key="owner_type" sort-key="owner_type">{{ $t('data_access.owner_type') }}</vs-th>
        <vs-th key="model_id" sort-key="model_id">{{ $t('data_access.model_id') }}</vs-th>
        <vs-th key="model_type" sort-key="model_type">{{ $t('data_access.model_type') }}</vs-th>
        <vs-th key="institution_name" sort-key="institution_name">{{ $t('data_access.institution') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="10%" key="owner_id">
            <div> {{ tr.owner_id }}</div>
          </vs-td>
          <vs-td width="20%" key="owner_type">
            <div> {{ tr.owner_type }}</div>
          </vs-td>
          <vs-td width="10%" key="model_id">
            <div> {{ tr.model_id }}</div>
          </vs-td>
          <vs-td width="20%" key="model_type">
            <div> {{ tr.model_type }}</div>
          </vs-td>
          <vs-td width="20%" key="institution_name">
            <div> {{ tr.institution_name }}</div>
          </vs-td>
          <vs-td width="15%">
            <feather-icon
              v-permission="'data_access.edit'"
              icon="EditIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
              @click="props.edit(props.data[indextr].id)"/>
            <feather-icon
              v-permission="'data_access.delete'"
              icon="Trash2Icon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
              @click="props.destroy(props.data[indextr])"/>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import DataAccessService from '@/services/api/DataAccessService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/data_access/create')
    }
  },
  beforeMount() {
    this.service = DataAccessService.build(this.$vs)
  }
}
</script>
