var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "data_access.create",
              expression: "'data_access.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "data_access",
          order_column: "model_type",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c(
                  "vs-th",
                  { key: "owner_id", attrs: { "sort-key": "owner_id" } },
                  [_vm._v(_vm._s(_vm.$t("data_access.owner_id")))]
                ),
                _c(
                  "vs-th",
                  { key: "owner_type", attrs: { "sort-key": "owner_type" } },
                  [_vm._v(_vm._s(_vm.$t("data_access.owner_type")))]
                ),
                _c(
                  "vs-th",
                  { key: "model_id", attrs: { "sort-key": "model_id" } },
                  [_vm._v(_vm._s(_vm.$t("data_access.model_id")))]
                ),
                _c(
                  "vs-th",
                  { key: "model_type", attrs: { "sort-key": "model_type" } },
                  [_vm._v(_vm._s(_vm.$t("data_access.model_type")))]
                ),
                _c(
                  "vs-th",
                  {
                    key: "institution_name",
                    attrs: { "sort-key": "institution_name" },
                  },
                  [_vm._v(_vm._s(_vm.$t("data_access.institution")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "owner_id", attrs: { width: "10%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.owner_id))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "owner_type", attrs: { width: "20%" } },
                      [_c("div", [_vm._v(" " + _vm._s(tr.owner_type))])]
                    ),
                    _c("vs-td", { key: "model_id", attrs: { width: "10%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.model_id))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "model_type", attrs: { width: "20%" } },
                      [_c("div", [_vm._v(" " + _vm._s(tr.model_type))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "institution_name", attrs: { width: "20%" } },
                      [_c("div", [_vm._v(" " + _vm._s(tr.institution_name))])]
                    ),
                    _c(
                      "vs-td",
                      { attrs: { width: "15%" } },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "data_access.edit",
                              expression: "'data_access.edit'",
                            },
                          ],
                          attrs: {
                            icon: "EditIcon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                          },
                          on: {
                            click: function ($event) {
                              return props.edit(props.data[indextr].id)
                            },
                          },
                        }),
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "data_access.delete",
                              expression: "'data_access.delete'",
                            },
                          ],
                          attrs: {
                            icon: "Trash2Icon",
                            svgClasses:
                              "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                          },
                          on: {
                            click: function ($event) {
                              return props.destroy(props.data[indextr])
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }